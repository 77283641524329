import React from "react"
import {
  Typography,
  Card, 
  CardContent,
  CardMedia, 
  CardHeader,
  CardActions,
  Link,
  
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    height:"230px",
  },
  details: {
    display: "flex",
    flexDirection: 'column',
    
  },
  content:{
    padding:'5px',

  },
  cover: {
   
    width:'100%',
    height:'230px'
  },
}))

export default (props) => {
  const classes = useStyles()
  return (
    
    <Link underline='none' rel="noopener" target="_blank" style={{display:'block'}} href={props.link}>
    <Card raised className={classes.card}>
       <CardMedia
        className={classes.cover}
        image={props.img}
        title={props.title}
      />
   
     
      <div className={classes.details}>
      <CardHeader 
      className={classes.content}
      title={props.title}
      subheader={props.subtitle}
    />
        <CardContent className={classes.content}>
          
          <Typography variant="body1" color="textSecondary">
            {props.text}
          </Typography>
        </CardContent>
        <CardActions>
          
        </CardActions>
      </div>
    </Card>
    </Link>
    
  )
}
