import React from "react"
import { Container, Paper, Typography, Grid, Divider, Link } from "@material-ui/core"
import Layout from "../components/Layout"
import Publication from "../components/PublicationCard"
import { makeStyles } from "@material-ui/core/styles"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { MdMail } from "react-icons/md"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  container: {
    marginTop: "30px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
  },
  heroContent: {
    marginTop:50,
    height: "250px",
    objectFit: "contain",
    marginBottom: "-57px",

    /*background: 'linear-gradient(to right, #2980b9, #6dd5fa)',
    backgroundSize: "cover",
    padding: theme.spacing(8, 0, 6),
    color: "white",
    height: "250px",
    //backgroundPositionY:'40%',
    backgroundPositionX: "center",
    padding: 0,*/
    //marginBottom: "-70px",

    [theme.breakpoints.up("sm")]: {
      height: "500px",
      marginTop:50,      
      marginBottom: "-120px",
    },

    [theme.breakpoints.up("md")]: {
      height: "600px",
      marginTop:20,      
      marginBottom: "-150px",
    },
    [theme.breakpoints.up("1023px")]: {
      height: "750px",
      marginBottom: "-200px",
      
    },

    [theme.breakpoints.up("lg")]: {
      height: "750px",
      marginBottom: "-180px",
      marginTop:40,
    },

    [theme.breakpoints.up("xl")]: {
      height: "100vh",
      marginTop:87,
      marginBottom: "-185px",
    },
  },
}))

export default function Index(props) {
  const classes = useStyles()
  /*const Parrafo = ({ text }) => (
    <Typography align="justify" gutterBottom paragraph variant="body1">
      {text}
    </Typography>
  )
  console.log(props.data.coverPMU)*/
  const coverImg = props.data.headerImg.childImageSharp.fluid

  return (
    <Layout>
      <BackgroundImage
        className={classes.heroContent}
        fluid={coverImg}
        loading="eager"
        
      />

      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography align="center" variant="h4">
            ¡Bienvenidos!
          </Typography>
          <Grid container spacing={3} className={classes.container}>
            <Grid container item xs={12} spacing={3}>
              <Grid item sm={6}>
                <Typography paragraph>
                  El Coloquio Uruguayo de Matemática es un evento bienal que se
                  desarrolla desde 2007, ideado para estimular la investigación
                  y la difusión de la Matemática en el Uruguay y la interacción
                  entre las diferentes comunidades vinculadas profesionalmente a
                  la Matemática.
                </Typography>
                <Typography paragraph>
                  Está fundamentalmente dirigido a investigadores en Matemática
                  y otras disciplinas, estudiantes de grado y posgrado de
                  Matemática y áreas afines, docentes universitarios, del
                  Consejo de Formación en Educación, de los Consejos de
                  Educación Secundaria, Educación Técnico Profesional y
                  Educación Inicial y Primaria, y estudiantes del Consejo de
                  Formaciòn en Educación.
                </Typography>
                <Typography paragraph>
                  Tradicionalmente se ha organizado en la semana anterior al 24
                  y 25 de diciembre, aprovechando las visitas de fin de año para
                  reunir a los matemáticos uruguayos radicados en el exterior
                  con los colectivos locales.
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography paragraph>
                  El Coloquio propone cursos cortos, ponencias, charlas
                  plenarias y talleres, de varios niveles, a cargo de
                  Investigadores uruguayos radicados en el país o en el exterior
                  y de invitados extranjeros.
                </Typography>
                <Typography paragraph>
                  La edición de 2019 será precedida de un encuentro nacional de
                  docentes de Matemática en las orientaciones de Arquitectura y
                  Diseño. <br />
                  Por más información se dirigirse a:
                  <Link
                    target="_top"
                    rel="noopener noreferrer"
                    href="mailto:matematica@fadu.edu.uy"
                    color="primary"
                  >
                    <MdMail style={{verticalAlign:'middle', marginLeft: 5 }} /> matematica@fadu.edu.uy
                  </Link>
                </Typography>
                <Typography paragraph>
                  La organización del Coloquio prevé además espacios de
                  socialización y de encuentro, que promuevan el intercambio
                  entre los participantes y la creación y fortalecimiento de
                  vínculos entre los colectivos que integran.
                </Typography>
                <Typography paragraph>
                  El evento es gratuito y requiere inscripción. La organización
                  dispone de recursos para facilitar la participación de
                  interesados que requieran ayuda económica, especialmente para
                  docentes y estudiantes del interior del país.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={12} lg={8}>
                <Img
                  loading="eager"
                  fluid={props.data.collage.childImageSharp.fluid}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <strong style={{ fontWeight: 500 }}>
                  Organizadores del Coloquio 2019:
                </strong>{" "}
                Mariana Haim, Omar Gil, Federico Dalmao, Victoria García,
                Marcelo Forets, Elisa Rocha
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" variant="h4">
                  Publicaciones
                </Typography>
                <Typography align="center" variant="body2">
                  Los Coloquios son editados por las PMU (Publicaciones
                  Matemáticas del Uruguay)
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Publication
                  title="Publicaciones Matemáticas del Uruguay"
                  subtitle="Volumen 15"
                  text="Segundo y cuarto Coloquios"
                  img={props.data.coverPMU.childImageSharp.fixed.src}
                  link="http://pmu.uy/pmu15"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Publication
                  title="Publicaciones Matemáticas del Uruguay"
                  subtitle="Volumen 13"
                  text="Tercer Coloquio"
                  img={props.data.coverPMU.childImageSharp.fixed.src}
                  link="http://pmu.uy/pmu13"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Publication
                  title="Publicaciones Matemáticas del Uruguay"
                  subtitle="Volumen 17"
                  text="Proceedings del Sexto Coloquio"
                  img={props.data.coverPMU.childImageSharp.fixed.src}
                  link="http://pmu.uy/pmu17"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Divider />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query {
    collage: file(relativePath: { eq: "collage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImg: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverPMU: file(relativePath: { eq: "cover-pmu.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
